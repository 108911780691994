import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useDocumentListingQuery, useGetItemQuery } from 'generated/graphql';
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

export const ItemDetail = (props: any) => {
  const { mode }: any = props;
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();
  const { ItemID, ItemCategoryID }: any = useParams();
  let location = useLocation();
  const editData = location?.state as any;

  // QUERY //

  const {
    loading: ItemLoading,
    error: ItemError,
    data: { getItem } = {
      getItem: [],
    },
  } = useGetItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      ItemID: ItemID,
    },
  });

  return (
    <>
      {ItemLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-setting/item-category/${ItemCategoryID}`)
        }
        smTitle={smTitle.GENERAL_SETTINGS}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Item' },
          { name: 'Item', current: true },
        ]}
      />

      <ContentWrapper>
        <CardContents
          section={{
            header: {
              title: 'Item Details',
            },
          }}
        >
          <div className="content-wrap left">
            <div className="desc">Name</div>
            <div className="xsTitle">{getItem[0]?.Name}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Item Category</div>
            <div className="xsTitle">{getItem[0]?.ItemCategory?.Name}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Description</div>
            <div className="xsTitle">{getItem[0]?.Description}</div>
          </div>
          <div className="content-wrap left">
            <div className="desc">UOM</div>
            <div className="xsTitle">{getItem[0]?.UOM?.Name}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Tax Code</div>
            <div className="xsTitle">{getItem[0]?.TaxScheme?.Code}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Variance Percentage</div>
            <div className="xsTitle">{getItem[0]?.VariancePerc}%</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Remark</div>
            <div className="xsTitle">{getItem[0]?.Remark}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Default Supplier</div>
            <div className="xsTitle">
              {getItem[0]?.DefaultSupplier?.CompanyName}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc">IsInventory</div>
            <div className="xsTitle">
              {getItem[0]?.IsInventory === true ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc">IsCentralised</div>
            <div className="xsTitle">
              {getItem[0]?.IsCentralised === true ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Status</div>
            <div
              className="xsTitle"
              style={{ display: 'flex', marginTop: '3px' }}
            >
              {getItem[0]?.RecordStatus === 'ACTIVE' && (
                <div
                  className="blue-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Active
                </div>
              )}
              {getItem[0]?.RecordStatus === 'INACTIVE' && (
                <div
                  className="grey-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Inactive
                </div>
              )}
            </div>
          </div>
        </CardContents>
      </ContentWrapper>
    </>
  );
};
