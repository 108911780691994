import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@material-ui/core'
import React from 'react'

interface ICardAddOnProps {
  children: any
  style?: Record<string, string>
  contentStyle?: Record<string, string>
  IsDifferentColour?: boolean
  IsCheckboxHeader?: boolean
  section?: {
    header?: {
      title?: any
      dollarIcon?: any
      rightTitle?: any
      rightTitleWithCheckBox?: any
      customHeaderColor?: any
      customTextColor?: any
      customFontSizeClass?: any
      onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
      icon?: any
      leftIcon?: any
      disabled?: boolean
      iconButton?: boolean
      component?: any
      materialIcon?: boolean
    }
    footer?: {
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
      label?: string
    }
  }
}

export function CardContents(props: ICardAddOnProps) {
  const {
    children,
    section,
    style,
    contentStyle,
    IsDifferentColour,
    IsCheckboxHeader,
  } = props

  const asSwitch = section?.header?.iconButton ? false : true
  return (
    <Box
      className={`${(IsDifferentColour && 'card-form-darkblue') ||
        (!IsDifferentColour && 'card-form')}`}
      // className={
      //   IsDifferentColour === true ? 'card-form-darkblue' : 'card-form'
      // }
      style={style}
    >
      <Card>
        {section?.header && (
          <CardHeader
            className={section?.header?.leftIcon ? 'card-with-icon' : ''}
            // subheader={
            //   section?.header?.leftIcon ? (
            //     <img
            //       style={{ width: '20px', height: '20px' }}
            //       src={section?.header?.leftIcon}
            //     />
            //   ) : null

            //   // section?.header?.leftIcon ? (
            //   //       <img
            //   //         style={{ width: '20px', height: '20px', marginTop: '5px' }}
            //   //         src={section?.header?.leftIcon}
            //   //       />
            //   //     ) : null
            // }
            // avatar={
            //   section?.header?.leftIcon ? (
            //     <img
            //       style={{ width: '20px', height: '20px', marginTop: '5px' }}
            //       src={section?.header?.leftIcon}
            //     />
            //   ) : null
            // }
            disableTypography
            style={{
              backgroundColor: `${
                section?.header?.customHeaderColor
                  ? `${section?.header?.customHeaderColor}`
                  : null
              }`,
              color: `${
                section?.header?.customTextColor
                  ? `${section?.header?.customTextColor}`
                  : null
              }`,
              paddingTop: IsCheckboxHeader ? '0px' : null,
              paddingBottom: IsCheckboxHeader ? '0px' : null,
            }}
            title={
              <>
                <div className="infoline-content">
                  <span
                    className={`${
                      section?.header?.customFontSizeClass
                        ? section?.header?.customFontSizeClass
                        : 'smTitle'
                    } flex-space`}
                  >
                    {section?.header?.title}{' '}
                  </span>
                  {section?.header?.dollarIcon && (
                    <span className="smTitle ">
                      {section?.header?.dollarIcon}
                    </span>
                  )}
                  {section?.header?.rightTitle && (
                    <span
                      className={`${
                        section?.header?.customFontSizeClass
                          ? section?.header?.customFontSizeClass
                          : 'smTitle'
                      }`}
                    >
                      {section?.header?.rightTitle}
                    </span>
                  )}
                  {section?.header?.rightTitleWithCheckBox && (
                    <span
                      className={`${
                        section?.header?.customFontSizeClass
                          ? section?.header?.customFontSizeClass
                          : 'smTitle'
                      }`}
                      style={{ marginTop: '9px' }}
                    >
                      {section?.header?.rightTitleWithCheckBox}
                    </span>
                  )}
                </div>
              </>
            }
            action={
              asSwitch ? (
                <IconButton
                  disabled={section?.header?.disabled}
                  className="card-header-btn"
                  aria-label="edit"
                  onClick={section?.header?.onClickAction}
                >
                  {section?.header?.icon}
                </IconButton>
              ) : (
                <>{section?.header?.component}</>
              )
            }
          />
        )}

        <CardContent style={contentStyle}>{children}</CardContent>
      </Card>
      {section?.footer && (
        <Box className="btn-container">
          <Button
            color="primary"
            variant="contained"
            onClick={section?.footer?.onClick}
          >
            {section?.footer?.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CardContents
