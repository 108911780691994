import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';

export const ErrorDialog = (props: any) => {
  const {
    errorDia,
    setErrorDia,
    errorMsg,
    errorHeaderMsg,
    isApprovalPolicy = false,
    onclick,
  } = props;

  return (
    <CommonDialog
      fullWidth={true}
      open={errorDia}
      onClose={() => {
        isApprovalPolicy ? onclick() : setErrorDia(false);
      }}
      sections={{
        header: {
          children: (
            <ListItem className="remove-padding">
              <ListItemText
                primary={
                  <>
                    <span
                      className="smTitle flex-space"
                      style={{ color: 'red' }}
                    >
                      {!!errorHeaderMsg ? errorHeaderMsg : 'Error!'}
                    </span>
                  </>
                }
              />
            </ListItem>
          ),
        },
        body: () => (
          <div>
            <span>{errorMsg}</span>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () =>
                  isApprovalPolicy ? onclick() : setErrorDia(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  );
};
